import React from "react";
import { Form } from "react-final-form";
import { Grid } from "@material-ui/core";
import { Screen } from "./shared";
import { LoginResult, useResetPasswordMutation } from "../../generated/graphql";
import RffPassword from "../../components/inputs/RffPassword";
import * as VALIDATORS from "../../helpers/validators";
import RffTextField from "../../components/inputs/RffTextField";
import { withToken } from "./helpers/withToken";
import { LoadingButton } from "@material-ui/lab";

interface ResetPasswordFormProps {
  email: string;
  token: string;
  setScreen: (screen: Screen) => void;
  onLoginSuccess: () => void;
}

interface ResetPasswordFormPayload {
  newPassword: string;
  confirmPassword: string;
}

const ResetPasswordForm = ({
  email,
  token,
  setScreen,
  onLoginSuccess,
}: ResetPasswordFormProps) => {
  const [resetPasswordMutation, { loading }] = useResetPasswordMutation();

  const resetPassword = async (formPayload: ResetPasswordFormPayload) => {
    const result = await resetPasswordMutation({
      variables: {
        email,
        token,
        newPassword: formPayload.newPassword,
      },
    });

    if (result.data?.resetPassword?.result === LoginResult.Success) {
      onLoginSuccess();
      return;
    }

    if (result.data?.resetPassword?.result === LoginResult.OldPassword) {
      return {
        newPassword: "New Password can not be same as your password.",
      };
    }

    return { newPassword: "Password Update Failed" };
  };

  const validate = (formData: Partial<ResetPasswordFormPayload>) => {
    const { newPassword, confirmPassword } = formData;
    return {
      newPassword: VALIDATORS.password(newPassword || ""),
      confirmPassword: VALIDATORS.matches(confirmPassword, newPassword),
    };
  };

  return (
    <Form
      onSubmit={resetPassword}
      validate={validate}
      initialValues={{ email }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <RffTextField
              name="email"
              type="email"
              label="Email"
              required
              disabled={true}
              autoComplete="username"
              fullWidth
            />
            <RffPassword
              name="newPassword"
              label="New Password"
              required
              disabled={loading}
              autoComplete="new-password"
              validatePassword
              fullWidth
              autoFocus
            />
            <RffPassword
              name="confirmPassword"
              label="Confirm New Password"
              required
              disabled={loading}
              autoComplete="new-password"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
            >
              Reset Password
            </LoadingButton>
          </Grid>
        </form>
      )}
    />
  );
};

export default withToken(
  ResetPasswordForm,
  "Reset Link Has Expired",
  "Send New Password Reset Email"
);
