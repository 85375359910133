import { Address, AddressInput } from "../generated/graphql";

export const required = (value?: string | null) => {
  return !value ? "Required" : undefined;
};

export const minimum = (value: string, length: number) => {
  return !value || value.length < length
    ? `Must be at least ${length} characters long.`
    : undefined;
};

export const maximum = (value: string, length: number) => {
  return !value || value.length > length
    ? `Must be less than ${length} characters long.`
    : undefined;
};

export const containsCapitolLetters = (value: string) => {
  return !/[A-Z]/.test(value)
    ? "Must contain at least 1 capital letter"
    : undefined;
};

export const email = (value?: string | null) => {
  const pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return value && pattern.test(value.toLowerCase())
    ? undefined
    : "Please enter a valid email address";
};

export const phone = (value?: string | null) => {
  const pattern = new RegExp(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  );
  return value && pattern.test(value.toLowerCase())
    ? undefined
    : "Please enter a valid phone number";
};

export const matches = (proposed?: string | null, model?: string | null) => {
  return proposed && model && proposed === model
    ? undefined
    : "Passwords do not match";
};

// Password Verification
export const password = (password: string, currentPassword?: string) => {
  return (
    required(password) ||
    (currentPassword &&
      password === currentPassword &&
      "New Password can not be same as your password") ||
    minimum(password, 8) ||
    containsCapitolLetters(password) ||
    undefined
  );
};

// Address Input
export const addressInputValidator = (data: AddressInput) => {
  return {
    name1: required(data?.name1) || maximum(data?.name1, 255),
    address1: required(data?.address1),
    city: required(data?.city),
    postal: required(data?.postal),
    country: required(data?.country),
    phone: phone(data?.phone),
    email: email(data?.email),
  };
};
export const addressInputInitialValues = {
  name1: "",
  name2: "",
  address1: "",
  address2: "",
  city: "",
  postal: "",
  state: "",
  country: "",
  phone: "",
  email: "",
};
// we want to strip the id and __typename to convert Address to an AddressInput
export const toAddressInput = (
  address?: Address | AddressInput | null
): AddressInput | null => {
  return address
    ? {
        ...{
          ...address,
          id: undefined,
          __typename: undefined,
        },
      }
    : null;
};
