import FormDialog from "../../components/modals/FormDialog";

const GOOGLE_FORM_URL =
  "https://docs.google.com/forms/d/e/1FAIpQLScgUEjG6wqSMZnRMfuLtiSz0IQSg12CqNuroQU-i0uoomW-wg/viewform";

const ReportBugDialog = ({ open, onClose }) => {
  const onSubmit = () => {
    window.open(GOOGLE_FORM_URL, "_blank");
    onClose();
  };

  return (
    <FormDialog
      open={open}
      isSubmitting={false}
      onClose={onClose}
      title="Report a Bug"
      onSave={onSubmit}
      actionButtonLabel="Go to Google Form"
    >
      <>You are about to be taken to a Google Form. Continue?</>
    </FormDialog>
  );
};

export default ReportBugDialog;
