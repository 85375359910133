import React, { useContext, useEffect } from "react";
import { Alert, AlertTitle, makeStyles, Theme } from "@material-ui/core";
import { AlertType, AlertContext } from "./AlertContextProvider";

interface AlertProps {
  duration?: number;
  alert: AlertType;
  handleClose: (alert: AlertType) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "fixed",
    left: theme.spacing(15),
    bottom: theme.spacing(2),
    zIndex: 2000,
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

export default function AlertNotification() {
  const classes = useStyles();
  const alertContext = useContext(AlertContext);

  const handleClose = (alert: AlertType) => {
    alertContext?.removeAlert(alert?.id);
  };

  return (
    <div className={classes.root}>
      {alertContext
        ? alertContext?.alerts.length > 0 &&
          alertContext?.alerts.map((alert: AlertType, index: number) => (
            <AlertProvider
              key={alert.id + index}
              alert={alert}
              handleClose={handleClose}
            />
          ))
        : null}
    </div>
  );
}

const AlertProvider: React.FC<AlertProps> = ({
  duration = 4000,
  alert,
  handleClose,
}) => {
  const classes = useStyles();

  useEffect(() => {
    const timer = setTimeout(() => handleClose(alert), duration);
    return () => {
      clearTimeout(timer);
    };
  }, [duration, alert, handleClose]);

  return (
    <Alert
      className={classes.alert}
      onClose={() => handleClose(alert)}
      id="alert"
      elevation={6}
      variant="filled"
      severity={alert.type}
    >
      <AlertTitle>{alert.title}</AlertTitle>
      {alert.text}
    </Alert>
  );
};
