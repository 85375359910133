import { Typography, TextField, Button, Box } from "@material-ui/core";
import React, { useState } from "react";
import { LoginResult, useLoginMutation } from "../../generated/graphql";
import { Screen } from "./shared";
import PasswordInput from "../../components/inputs/password";
import ResendTokenButton from "./components/ResendTokenButton";
import { LoadingButton } from "@material-ui/lab";

interface LoginFormProps {
  onLoginSuccess: () => void;

  email: string;
  setEmail: (email: string) => void;

  password: string;
  setPassword: (password: string) => void;

  setScreen: (screen: Screen) => void;
}

const messages = {
  credentials: "Email or Password is invalid.",
  unregistered:
    "An account has been allocated for this address but is inactive. Check you inbox for activation instructions.",
};

const LoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
  setScreen,
  onLoginSuccess,
}: LoginFormProps) => {
  const [login, { loading }] = useLoginMutation();
  const [badLoginReason, setBadLoginReason] = useState<
    null | "credentials" | "unregistered" | "blocked"
  >(null);

  const tryLogin = () =>
    login({
      variables: { email, password },
    }).then((response) => {
      const loginResult = response.data?.login?.result || LoginResult.Failure;

      switch (loginResult) {
        case LoginResult.Expired:
          setScreen(Screen.UpdatePassword);
          return;
        case LoginResult.Failure:
          setBadLoginReason("credentials");
          return;
        case LoginResult.Unregistered:
          setBadLoginReason("unregistered");
          return;
        case LoginResult.Success:
          onLoginSuccess();
          return;
        case LoginResult.Blocked:
          setBadLoginReason("blocked");
          return;
      }
    });

  if (badLoginReason === "unregistered") {
    return (
      <Box p={3}>
        <Typography>
          We found your account! But it needs to be registered.
        </Typography>
        <ResendTokenButton
          email={email}
          resetMessage="Send Registration Email"
        />
      </Box>
    );
  }

  return (
    <>
      <Box flexGrow={1}>
        {badLoginReason === "blocked" ? (
          <>
            <Typography
              variant="h6"
              textAlign="center"
              color="error"
              gutterBottom
            >
              Your Account Has Been Locked
            </Typography>
            <Typography variant="body1">
              Your account has been locked due to too many failed login
              attempts.
            </Typography>
            <Typography variant="body1">
              To unlock your account and access the data management portal,
              please reset your password.
            </Typography>

            <ResendTokenButton
              email={email}
              resetMessage="Reset Password"
            ></ResendTokenButton>
          </>
        ) : (
          <form onSubmit={(event) => tryLogin() && event.preventDefault()}>
            {badLoginReason && (
              <Typography variant="body1" color="error">
                {messages.credentials}
              </Typography>
            )}
            <TextField
              autoFocus
              label="Email"
              type="email"
              autoComplete="username"
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
              sx={{ mb: 1 }}
              disabled={loading}
            />
            <PasswordInput
              label="Password"
              autocomplete="current-password"
              password={password}
              setPassword={setPassword}
              disabled={loading}
              gutterBottom
              required
            />
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              loading={loading}
            >
              Login
            </LoadingButton>
          </form>
        )}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="flex-end">
        <Button
          type="submit"
          fullWidth
          size="large"
          onClick={() => setScreen(Screen.RequestAccess)}
        >
          Request Access
        </Button>
        <Button
          fullWidth
          variant="text"
          color="secondary"
          onClick={() => setScreen(Screen.ForgotPassword)}
        >
          Forgot Password
        </Button>
      </Box>
    </>
  );
};

export default LoginForm;
