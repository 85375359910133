import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { Components } from "@material-ui/core/styles/components";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";

// global theme variables
export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  blue: "#008fd0",
  red: "#ec5353",
  green: "#1eb980",
  yellow: "#FDB30050",
  gray: "#757575",
  darkBackground: "#171717",
  darkTransparent: "rgba(0,0,0, .3)",
  lightBackground: "#FFF",
  lightTransparent: "rgba(255,255,255,0.9)",
};

const components: Components = {
  MuiButton: {
    styleOverrides: {
      contained: {
        boxShadow: "none",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      margin: "normal",
      variant: "filled",
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: "filled",
    },
  },
  MuiInputLabel: {
    defaultProps: {
      variant: "filled",
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        backdropFilter: "blur(1px)",
        borderRadius: "8px",
      },
    },
  },
  MuiCard: {
    defaultProps: {
      elevation: 0,
    },
  },
};

const bodyText =
  "'Infer', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
const headingText =
  "'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";

const typography: TypographyOptions = {
  fontSize: 13,
  fontFamily: bodyText,
  h1: {
    fontFamily: headingText,
    fontWeight: 200,
  },
  h2: {
    fontFamily: headingText,
    fontWeight: 200,
  },
  h3: {
    fontFamily: headingText,
    fontWeight: 200,
  },
  h4: {
    fontFamily: headingText,
    fontWeight: 200,
  },
  h5: {
    fontFamily: headingText,
  },
  h6: {
    fontFamily: headingText,
  },
};
export const lightTheme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      mode: "light",
      primary: {
        main: colors.blue,
      },
      secondary: {
        main: colors.gray,
      },
      background: {
        transparent: colors.lightTransparent,
        paper: colors.lightBackground,
      },
      success: {
        main: colors.green,
      },
      error: {
        main: colors.red,
      },
    },
    typography,
    components: components,
  })
);

// main theme
export const darkTheme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      mode: "dark",
      primary: {
        main: colors.blue,
      },
      secondary: {
        main: colors.gray,
      },
      success: {
        main: colors.green,
      },
      error: {
        main: colors.red,
      },
      background: {
        transparent: colors.darkTransparent,
        paper: colors.darkBackground,
      },
    },
    typography,
    components: components,
  })
);

// chart theme
const strokes = {
  strokeLinecap: "round",
  strokeJoin: "round",
  strokeLineJoint: "round",
};

export const chartTheme = {
  axis: {
    style: {
      axis: {
        fill: "transparent",
        stroke: "transparent",
        strokeWidth: 1,
        ...strokes,
      },
      grid: {
        fill: "none",
        stroke: colors.darkTransparent,
        pointerEvents: "painted",
        ...strokes,
        strokeDashArray: "4",
      },
      ticks: {
        fill: "transparent",
        size: 1,
        stroke: "transparent",
        strokeWidth: 1,
        ...strokes,
      },
    },
  },
  line: {
    style: {
      data: {
        fill: "transparent",
        opacity: 1,
        stroke: colors.blue,
        strokeWidth: 1.5,
      },
      labels: {
        fontFamily: typography.fontFamily,
        fontSize: typography.fontSize,
        letterSpacing: "normal",
        fill: colors.blue,
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
  },
  bar: {
    style: {
      data: {
        fill: colors.blue,
        opacity: 1,
        stroke: colors.blue,
        strokeWidth: 1.5,
      },
      labels: {
        fontFamily: typography.fontFamily,
        fontSize: typography.fontSize,
        letterSpacing: "normal",
        fill: colors.blue,
        stroke: "transparent",
        strokeWidth: 0,
      },
    },
  },
};
