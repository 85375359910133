import React, { FunctionComponent, useState } from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { Route, useHistory } from "react-router-dom";
// ts-ignore
import { AnimatedSwitch } from "react-router-transition";

import { Screen } from "./shared";
import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";
import ResetPasswordForm from "./ResetPasswordForm";
import UpdatePasswordForm from "./UpdatePasswordForm";
import AstroLogo from "../../logo.png";
import RequestAccessForm from "./RequestAccess";
import Register from "./Register";
import ConfirmEmail from "./ConfirmEmail";

interface LoginContainerProps {}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.transparent,
    padding: theme.spacing(3),
    minHeight: "100vh",
    // width: '100%',
    [theme.breakpoints.up("sm")]: {
      width: "375px",
      minHeight: "440px",
    },
  },

  switchWrapper: {
    position: "relative",
    "& > div": {
      position: "absolute",
      minHeight: "100vh",
      width: "100vw",
    },
  },
}));

const LoginLayout: FunctionComponent<LoginContainerProps> = ({ children }) => {
  const { paper } = useStyles();

  return (
    <Box
      display="flex"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Paper className={paper}>
          <img
            src={AstroLogo}
            alt="Astronautics Data Management Portal"
            style={{
              display: "block",
              margin: "0 auto",
              maxWidth: "100%",
            }}
          />
          <Box mt={3} display="flex" flexDirection="column" flexGrow={1}>
            {children}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

interface LoginProps {
  onLoginSuccess: () => void;
}

const Login = ({ onLoginSuccess }: LoginProps) => {
  const history = useHistory();
  const { switchWrapper } = useStyles();

  const setScreen = (screen: Screen) => {
    history.push(screen);
  };

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className={switchWrapper}
    >
      <Route path={Screen.RequestAccess}>
        <LoginLayout>
          <RequestAccessForm setScreen={setScreen} />
        </LoginLayout>
      </Route>

      <Route path={Screen.ForgotPassword}>
        <LoginLayout>
          <ForgotPassword
            email={email}
            setEmail={setEmail}
            setScreen={setScreen}
            onLoginSuccess={onLoginSuccess}
          />
        </LoginLayout>
      </Route>

      <Route path={Screen.ResetPassword}>
        <LoginLayout>
          <ResetPasswordForm
            setScreen={setScreen}
            onLoginSuccess={onLoginSuccess}
          />
        </LoginLayout>
      </Route>

      <Route path={Screen.Register}>
        <LoginLayout>
          <Register setScreen={setScreen} onLoginSuccess={onLoginSuccess} />
        </LoginLayout>
      </Route>

      {/* currently the same as `Screen.Register` */}
      <Route path={Screen.RegisterAccount}>
        <LoginLayout>
          <Register setScreen={setScreen} onLoginSuccess={onLoginSuccess} />
        </LoginLayout>
      </Route>

      <Route exact path={Screen.UpdatePassword}>
        <LoginLayout>
          <UpdatePasswordForm
            initialData={{ email, password }}
            onLoginSuccess={onLoginSuccess}
            setScreen={setScreen}
          />
        </LoginLayout>
      </Route>

      <Route path={Screen.ConfirmEmail}>
        <LoginLayout>
          <ConfirmEmail />
        </LoginLayout>
      </Route>

      <Route path={Screen.Login}>
        <LoginLayout>
          <LoginForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            onLoginSuccess={onLoginSuccess}
            setScreen={setScreen}
          />
        </LoginLayout>
      </Route>
    </AnimatedSwitch>
  );
};

export default Login;
