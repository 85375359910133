import { Button } from "@material-ui/core";
import { Screen } from "./shared";
import {
  useRegisterAccountMutation,
  RegisterAccountMutationVariables,
} from "../../generated/graphql";
import { Form } from "react-final-form";
import RffTextField from "../../components/inputs/RffTextField";
import RffPassword from "../../components/inputs/RffPassword";
import { FORM_ERROR } from "final-form";
import { withToken } from "./helpers/withToken";
import * as VALIDATORS from "../../helpers/validators";

interface RegistrationFormProps {
  email: string;
  token: string;
  setScreen: (screen: Screen) => void;
  onLoginSuccess: () => void;
}

type RegisterAccountFormData = Omit<
  RegisterAccountMutationVariables,
  "token"
> & {
  confirmPassword: string;
};

const Register = ({
  email,
  token,
  setScreen,
  onLoginSuccess,
}: RegistrationFormProps) => {
  const [registerAccountMutation, { loading }] = useRegisterAccountMutation();

  const updatePassword = ({
    email,
    name,
    newPassword,
  }: RegisterAccountFormData) => {
    return registerAccountMutation({
      variables: { token, email, name, newPassword },
    })
      .then(() => onLoginSuccess())
      .catch(() => ({ [FORM_ERROR]: "Registration Failed" }));
  };

  const validate = (formData: Partial<RegisterAccountFormData>) => ({
    email: VALIDATORS.email(formData.email),
    newPassword: VALIDATORS.password(formData.newPassword),
    confirmPassword: VALIDATORS.matches(
      formData.newPassword,
      formData.confirmPassword
    ),
  });

  return (
    <Form
      onSubmit={updatePassword}
      initialValues={{ email }}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <RffTextField
            name="email"
            label="Email"
            type="email"
            autoComplete="current-username"
            margin="normal"
            fullWidth
            disabled
            required
          />
          <RffTextField
            name="name"
            label="Name"
            type="text"
            margin="normal"
            disabled={loading}
            fullWidth
            autoFocus
            required
          />
          <RffPassword
            name="newPassword"
            label="Password"
            autoComplete="new-password"
            required
            disabled={loading}
            validatePassword
            fullWidth
          />
          <RffPassword
            name="confirmPassword"
            autoComplete="new-password"
            label="Confirm New Password"
            required
            disabled={loading}
            fullWidth
          />
          <Button
            sx={{ mt: 3 }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Register
          </Button>
        </form>
      )}
    />
  );
};

export default withToken(
  Register,
  "Registration Link is Expired",
  "Resend Registration Link"
);
