import React from "react";
import { Grid, Button } from "@material-ui/core";
import { Screen } from "./shared";
import ForgotPasswordForm from "./ForgotPasswordForm";
interface ForgotPasswordProps {
  email: string;
  setEmail: (email: string) => void;
  setScreen: (screen: Screen) => void;
  onLoginSuccess: () => void;
}

const ForgotPassword = ({
  email,
  setEmail,
  setScreen,
}: ForgotPasswordProps) => {
  return (
    <Grid container>
      <ForgotPasswordForm email={email} setEmail={setEmail} />

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={() => setScreen(Screen.Login)}
        >
          Back to Login
        </Button>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
