import { createContext, useReducer } from "react";

export interface AlertType {
  id: number;
  title: string;
  text: string;
  type: "success" | "error" | "info" | "warning";
}

interface AlertContextType {
  alerts: AlertType[];
  addAlert: (alert: AlertType) => void;
  removeAlert: (id: number) => void;
}

type Action =
  | { type: "ADD_ALERT"; payload: AlertType }
  | { type: "REMOVE_ALERT"; payload: number };

const AlertContext = createContext<AlertContextType | undefined>(undefined);
AlertContext.displayName = "AlertContext";

function alertReducer(state: AlertType[], action: Action): AlertType[] {
  switch (action.type) {
    case "ADD_ALERT":
      return [...state, action.payload];
    case "REMOVE_ALERT":
      return state.filter((alert) => alert.id !== action.payload);
    default:
      return state;
  }
}

const AlertContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(alertReducer, []);

  const addAlert = (alert: AlertType) =>
    dispatch({ type: "ADD_ALERT", payload: alert });
  const removeAlert = (id: number) =>
    dispatch({ type: "REMOVE_ALERT", payload: id });

  const value = { alerts: state, addAlert, removeAlert };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

export { AlertContext, AlertContextProvider };
