const BankSafeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="32"
      fill="none"
      version="1.1"
      viewBox="0 0 307.615 307.614"
      xmlSpace="preserve"
    >
      <path
        d="M69.923 231.131h164.261V66.87H69.923v164.261zM99.764 90.813c3.75-3.756 9.839-3.756 13.589 0l21.227 21.233c5.317-2.522 11.196-4.047 17.469-4.047 6.734 0 12.984 1.777 18.587 4.66l20.068-20.068c3.753-3.759 9.836-3.759 13.595 0 3.747 3.756 3.747 9.839 0 13.595L185.3 125.185c4.828 6.729 7.746 14.919 7.746 23.815 0 8.542-2.63 16.468-7.104 23.038l17.876 17.882c3.76 3.753 3.76 9.848 0 13.595-1.873 1.886-4.335 2.822-6.791 2.822s-4.918-.937-6.791-2.822L171.56 184.84c-5.83 3.183-12.418 5.164-19.512 5.164-6.272 0-12.151-1.525-17.457-4.048l-21.152 21.162a9.586 9.586 0 01-6.794 2.81 9.576 9.576 0 01-6.794-2.81c-3.759-3.76-3.759-9.854 0-13.596l19.738-19.756c-5.278-6.893-8.536-15.426-8.536-24.767 0-9.34 3.257-17.867 8.536-24.761l-19.825-19.831a9.609 9.609 0 010-13.594z"
        fill="white"
      ></path>
      <path
        d="M152.054 170.776c12.012 0 21.776-9.764 21.776-21.776 0-12.01-9.765-21.785-21.776-21.785-12.01 0-21.768 9.775-21.768 21.785.001 12.013 9.758 21.776 21.768 21.776z"
        fill="white"
      ></path>
      <path
        d="M288.39 0H19.219A14.417 14.417 0 004.807 14.427v269.156c0 3.986 1.604 7.59 4.215 10.195 2.615 2.606 6.218 4.228 10.196 4.228h12.25v9.608H69.695v-9.608h164.604v9.608h38.227v-9.608h15.865a14.43 14.43 0 0014.417-14.423V14.421C302.801 6.455 296.352 0 288.39 0zm-34.978 240.744a9.6 9.6 0 01-9.613 9.614H60.307a9.602 9.602 0 01-9.611-9.614v-30.816h-9.61V190.7h9.61v-83.47h-9.839V88.003h9.839V57.259a9.604 9.604 0 019.611-9.617h183.492c5.308 0 9.607 4.296 9.607 9.617v183.485h.006z"
        fill="white"
      ></path>
    </svg>
  );
};

export default BankSafeIcon;
