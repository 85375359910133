import React from "react";

import CellWifiIcon from "@material-ui/icons/CellWifi";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import SignalCellularOffIcon from "@material-ui/icons/SignalCellularOff";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { ProvisionAction } from "../generated/graphql";

interface ProvisionActionIconProps {
  provisionAction: ProvisionAction;
}

const ProvisionActionIcon = ({ provisionAction }: ProvisionActionIconProps) => {
  switch (provisionAction) {
    case ProvisionAction.Activate:
      return <CellWifiIcon />;
    case ProvisionAction.Lock:
      return <LockIcon />;
    case ProvisionAction.Unlock:
      return <LockOpenIcon />;
    case ProvisionAction.Deactivate:
      return <SignalCellularOffIcon />;
    case ProvisionAction.ChangeIccid:
      return <SwapHorizIcon />;
  }
};

export default ProvisionActionIcon;
