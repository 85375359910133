import { useCallback } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useUserContext } from "../components/userContext";
import { useRefreshTokenMutation } from "../generated/graphql";

const useTokenRefresh = () => {
  const { logout } = useUserContext();

  const [refreshTokenMutation, { loading }] = useRefreshTokenMutation();

  const refreshTokenOrLogOut = useCallback(
    (lastMovementTime: Date) => {
      const authToken = Cookies.get("auth");
      const decodedAuthToken: any = authToken?.length && jwt_decode(authToken);
      const currentTime: Date = new Date();
      const timeFiveMinutesAgo: Date = new Date(
        currentTime.getTime() - 5 * 60 * 1000
      );
      const expirationWindowStart: Date = new Date(
        decodedAuthToken?.exp * 1000 - 3000
      );
      const isTokenAboutToExpire: boolean = expirationWindowStart < currentTime;
      const isUserInMovementWindow = lastMovementTime >= timeFiveMinutesAgo;

      if (loading) {
        return;
      }

      if (authToken?.length && isTokenAboutToExpire) {
        isUserInMovementWindow ? refreshTokenMutation() : logout();
      }
    },
    [logout, refreshTokenMutation, loading]
  );

  useEffect(() => {
    let lastMovementTime = new Date();

    const updateLastMovement = () => {
      lastMovementTime = new Date();
    };
    window.addEventListener("click", updateLastMovement);
    window.addEventListener("scroll", updateLastMovement);
    window.addEventListener("mousemove", updateLastMovement);
    window.addEventListener("keypress", updateLastMovement);

    const checker = () => refreshTokenOrLogOut(lastMovementTime);

    const intervalId = window.setInterval(checker, 1000);

    return () => {
      window.removeEventListener("click", updateLastMovement);
      window.removeEventListener("onscroll", updateLastMovement);
      window.removeEventListener("mousemove", updateLastMovement);
      window.removeEventListener("onkeypress", updateLastMovement);
      window.clearInterval(intervalId);
    };
  }, [refreshTokenOrLogOut]);

  useEffect(() => {}, [refreshTokenOrLogOut]);
};

export default useTokenRefresh;
