import React, { FunctionComponent } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import LoadingButton from "@material-ui/lab/LoadingButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { Form } from "react-final-form";

import AstroDialog, { useStyles } from "./AstroDialog";

interface FormDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (data?: any) => void;
  isSubmitting: boolean;
  validate?: (data?: any) => any;
  initialValues?: object;
  title: string;
  subtitle?: React.ReactNode;
  actionButtonLabel?: string;
  leftAction?: React.ReactNode;
  autoFocusSubmit?: boolean;
  children: JSX.Element | JSX.Element[];
}

const FormDialog: FunctionComponent<FormDialogProps> = ({
  open,
  onClose,
  onSave,
  isSubmitting,
  validate,
  initialValues,
  title,
  subtitle,
  actionButtonLabel = "Save",
  leftAction,
  autoFocusSubmit,
  children,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AstroDialog
      fullWidth
      maxWidth="sm"
      fullScreen={smallScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
    >
      <Form
        onSubmit={onSave}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            id={`${title}-form`}
            style={{ height: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <DialogTitle
                disableTypography
                id="dialog-title"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h6">{title}</Typography>
                <IconButton className={classes.closeButton} onClick={onClose}>
                  <CancelIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent>
                {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
                {children}
              </DialogContent>
              <DialogActions
                sx={
                  leftAction ? { justifyContent: "space-between" } : undefined
                }
              >
                {leftAction && <Box>{leftAction}</Box>}
                <Box>
                  <Button
                    onClick={onClose}
                    color="secondary"
                    tabIndex="-1"
                    type="reset"
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    autoFocus={autoFocusSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {actionButtonLabel}
                  </LoadingButton>
                </Box>
              </DialogActions>
            </Box>
          </form>
        )}
      ></Form>
    </AstroDialog>
  );
};

export default FormDialog;
