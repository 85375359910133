import React from "react";
import { Field } from "react-final-form";
import { TextField, TextFieldProps } from "@material-ui/core";

export type RffTextFieldProps = Exclude<
  TextFieldProps,
  "name" | "focused" | "error"
> & {
  name: string;
  maxLength?: number;
  validate?: (value: any) => string | undefined;
  positiveOnly?: boolean;
};

const maxLengthValidator = (maxLength: number | undefined | null) =>
  maxLength
    ? (value) =>
        typeof value === "string" && value.length > maxLength
          ? `Must be ${maxLength} characters or less`
          : undefined
    : () => {};

const requiredValidator = (required: boolean | undefined | null) =>
  required ? (value) => (value ? undefined : "Required") : () => {};

const emailValidator = (type: string | undefined | null) =>
  type === "email"
    ? (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value.toLowerCase())
          ? undefined
          : "Email address is invalid.";
      }
    : () => {};

const onlyPositiveNumbersValidator = (positiveOnly: boolean) =>
  positiveOnly
    ? (value) => (value > 0 ? undefined : "Must be a positive number")
    : () => {};

const RffTextField = ({
  name,
  maxLength = 60,
  value,
  label,
  helperText,
  inputProps,
  required,
  positiveOnly = false,
  validate = () => undefined,
  ...rest
}: RffTextFieldProps) => (
  <Field
    name={name}
    label={label}
    value={value}
    validate={(value) =>
      validate(value) ||
      requiredValidator(required)(value) ||
      maxLengthValidator(maxLength)(value) ||
      emailValidator(rest.type)(value) ||
      onlyPositiveNumbersValidator(positiveOnly)(value)
    }
    render={({ label, input, meta }) => (
      <TextField
        {...rest}
        {...input}
        value={input.value}
        focused={meta.active}
        error={
          meta.touched &&
          !!(meta.error || (meta.submitError && !meta.modifiedSinceLastSubmit))
        }
        helperText={
          meta.touched &&
          !!(meta.error || (meta.submitError && !meta.modifiedSinceLastSubmit))
            ? meta.error || meta.submitError
            : helperText
        }
        label={label}
        required={required}
        inputProps={inputProps}
      />
    )}
  />
);

export default RffTextField;
