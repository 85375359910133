import React from "react";
import {
  Dialog,
  DialogProps,
  ThemeProvider,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { lightTheme } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    padding: 0,
    cursor: "pointer",
    float: "right",
  },
}));

const AstroDialog = (props: DialogProps) => (
  <ThemeProvider theme={lightTheme}>
    <Dialog {...props} />
  </ThemeProvider>
);

export default AstroDialog;
