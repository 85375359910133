import React from "react";
import {
  Card,
  CardContent,
  Collapse,
  List,
  Theme,
  Typography,
} from "@material-ui/core";
import ActionItem from "./ActionItem";
import { TransitionGroup } from "react-transition-group";
import { ProvisionStatus } from "../../generated/graphql";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ProvisionActionNotification } from ".";

interface ActionListProps {
  hideHeader?: boolean;
  provisionStatus: ProvisionStatus;
  actions: ProvisionActionNotification[];
  dismissAction: (actionId: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ provisionStatus }: Pick<ActionListProps, "provisionStatus">) => ({
    marginTop: theme.spacing(1),
    backgroundColor:
      provisionStatus === ProvisionStatus.Complete
        ? theme.palette.success.main
        : provisionStatus === ProvisionStatus.Failed
        ? theme.palette.error.main
        : theme.palette.background.paper,
  }),
}));

const title = {
  [ProvisionStatus.Complete]: "Complete Actions",
  [ProvisionStatus.Pending]: "Pending Actions",
  [ProvisionStatus.Failed]: "Failed Actions",
};

const ActionList = ({
  actions,
  hideHeader,
  provisionStatus,
  dismissAction,
}: ActionListProps) => {
  const classes = useStyles({ provisionStatus });
  return (
    <Card classes={{ root: classes.root }}>
      <CardContent>
        {!hideHeader && (
          <Typography variant="body2">{title[provisionStatus]}</Typography>
        )}
        <List disablePadding>
          <TransitionGroup>
            {actions.map((action) => (
              <Collapse key={action.id}>
                <ActionItem
                  title={title[provisionStatus]}
                  action={action}
                  dismissAction={dismissAction}
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </List>
      </CardContent>
    </Card>
  );
};

export default ActionList;
