import { MeQuery } from "../../generated/graphql";
import { UserContext, UserContextData } from "./types";

export const toUserDataFromAuth = (
  userData: MeQuery
): UserContextData | null => {
  if (!userData.me) {
    return null;
  }
  return {
    userId: userData.me.id,
    name: userData.me.name,
    email: userData.me.email,
    isAstroFinance: userData.me.isAstroFinance,
    isAstroAdmin: userData.me.isAstroAdmin,
    accounts: userData.me.accounts.map(({ account, role }) => ({
      id: account.id,
      name: account.name,
      role: role,
    })),
    currentAccount: userData.me.accounts[0].account.id,
  } as const;
};

export const toUserContext = (
  data: UserContextData,
  currentAccount: string | null,
  setCurrentAccount: (account: string | null) => void,
  onLogin: () => void,
  logout: () => void
): UserContext =>
  ({
    userId: data.userId,
    name: data.name,
    email: data.email,
    isAstroFinance: data.isAstroFinance,
    isAstroAdmin: data.isAstroAdmin,
    accounts: data.accounts,
    currentAccount:
      data.accounts.find((account) => account.id === currentAccount) ||
      data.accounts[0],
    setCurrentAccount: setCurrentAccount,
    onLogin: onLogin,
    logout: logout,
  } as const);
