import { Box, Button, FormHelperText, Typography } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { Form } from "react-final-form";
import RffTextField from "../../components/inputs/RffTextField";
import {
  useRequestAccessMutation,
  RequestAccessMutationVariables,
} from "../../generated/graphql";
import { Screen } from "./shared";
import * as VALIDATORS from "../../helpers/validators";
import { useState } from "react";

interface RequestAccessFormProps {
  setScreen: (screen: Screen) => void;
}

const RequestAccessForm = ({ setScreen }: RequestAccessFormProps) => {
  const [state, setState] = useState<null | "Success" | "Error">(null);
  const [requestAccess, { loading }] = useRequestAccessMutation();
  const onSubmit = (data: RequestAccessMutationVariables) => {
    requestAccess({ variables: data })
      .then(() => setState("Success"))
      .catch(() => setState("Error"));
  };

  if (state === "Success") {
    return (
      <Box>
        <Typography textAlign="center">
          Request received. We will be in touch
        </Typography>
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={() => setScreen(Screen.Login)}
          sx={{ mt: 1 }}
        >
          Back to Login
        </Button>
      </Box>
    );
  }

  const validate = (data: RequestAccessMutationVariables) => {
    return {
      email: VALIDATORS.email(data.email),
      phone: VALIDATORS.phone(data.phone),
    };
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          {state === "Error" && (
            <FormHelperText error>
              Something went wrong. Try again.
            </FormHelperText>
          )}
          <RffTextField
            label="Email"
            type="email"
            autoComplete="email"
            name="email"
            fullWidth
            required={true}
            disabled={loading}
          />
          <RffTextField
            label="Phone"
            type="phone"
            autoComplete="phone"
            name="phone"
            fullWidth
            required={true}
            disabled={loading}
          />
          <RffTextField
            label="Organization"
            type="text"
            name="organization"
            fullWidth
            required={true}
            disabled={loading}
          />
          <RffTextField
            label="Message"
            type="text"
            name="message"
            fullWidth
            multiline
            rows={3}
            disabled={loading}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            loading={loading}
            sx={{ mt: 1, mb: 1 }}
          >
            Request Access
          </LoadingButton>
          <Button
            fullWidth
            variant="text"
            color="primary"
            onClick={() => setScreen(Screen.Login)}
          >
            Back to Login
          </Button>
        </form>
      )}
    />
  );
};

export default RequestAccessForm;
