import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTokenInfoLazyQuery } from "../../../generated/graphql";
import LoadingIndicator from "../components/LoadingIndicator";
import ResendTokenButton from "../components/ResendTokenButton";
import { useQueryToken } from "../shared";

interface TokenInfo {
  email: string;
  token: string;
}

export function withToken<P extends TokenInfo>(
  WrappedComponent: React.ComponentType<P>,
  expiredTokenMessage: string,
  resetTokenButtonLabel: string
) {
  const Component = (props: Omit<P, keyof TokenInfo>) => {
    const token = useQueryToken();

    const tokenStr = token?.token;
    const email = token?.email;

    const [
      checkToken,
      { loading, data, error, called },
    ] = useTokenInfoLazyQuery();

    useEffect(() => {
      (async () => {
        if (tokenStr && email) {
          return checkToken({ variables: { token: tokenStr, email } });
        }
      })();
    }, [checkToken, tokenStr, email]);

    // fetching token info
    if (token && (loading || !called)) {
      return <LoadingIndicator />;
    }

    // token is bad
    if (!token || error || !data?.tokenInfo) {
      return (
        <Typography variant="h6" textAlign="center">
          Invalid Reset / Registration Link
        </Typography>
      );
    }

    // token is expired
    if (data?.tokenInfo?.tokenIsExpired) {
      return (
        <Box>
          <Typography variant="h6" textAlign="center">
            {expiredTokenMessage}
          </Typography>
          <ResendTokenButton
            email="email"
            resetMessage={resetTokenButtonLabel}
          ></ResendTokenButton>
        </Box>
      );
    }

    return (
      <WrappedComponent
        {...(props as P)}
        email={token.email}
        token={token.token}
      />
    );
  };

  return React.memo(Component);
}
