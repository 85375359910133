import {
  Badge,
  Card,
  CardContent,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import React, { useCallback, useContext, useReducer } from "react";
import {
  DeviceActionMutableFieldsFragment,
  DeviceActionsFragment,
  ProvisionStatus,
} from "../../generated/graphql";
import SimCardIcon from "@material-ui/icons/SimCard";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ActionList from "./ActionList";
import useDeviceWatcher from "./useDeviceWatcher";
import { useDialog } from "../../helpers/useDialog";

export type ProvisionActionNotification = DeviceActionsFragment & {
  device: DeviceActionMutableFieldsFragment;
};
interface UpsertAction {
  __typename: "upsert";
  action: ProvisionActionNotification;
}

interface DismissAction {
  __typename: "dismiss";
  actionId: string;
}

type NotificationContextValues = (action: ProvisionActionNotification) => void;

const notificationReducer = (
  actions: ProvisionActionNotification[],
  action: UpsertAction | DismissAction
) => {
  if (action.__typename === "upsert") {
    const newAction = action.action;

    // append provision action
    if (newAction.__typename === "ProvisionRequest") {
      const actionIndex = actions.findIndex(
        (action) => action.id === newAction.id
      );
      if (actionIndex > -1) {
        return actions.map((action, index) =>
          index === actionIndex ? newAction : action
        );
      } else {
        return [...actions, newAction];
      }
    }

    return actions;
  }

  if (action.__typename === "dismiss") {
    const dismissId = action.actionId;
    return actions.filter((action) => action.id !== dismissId);
  }

  return actions;
};

const NotificationContext = React.createContext<NotificationContextValues>(
  () => {
    throw Error();
  }
);

const getActionsByStatus = (
  status: ProvisionStatus,
  actions: ProvisionActionNotification[]
) =>
  actions.filter(
    (action) =>
      action.__typename === "ProvisionRequest" && action.status === status
  );

const Notifications: React.FC = ({ children }) => {
  const [actions, dispatch] = useReducer(notificationReducer, []);
  const { open, toggle } = useDialog();

  const addSingleAction = useCallback(
    (action) => dispatch({ __typename: "upsert", action }),
    [dispatch]
  );
  const dismissAction = useCallback(
    (actionId) => dispatch({ __typename: "dismiss", actionId }),
    [dispatch]
  );

  useDeviceWatcher(addSingleAction);
  const pendingActions = getActionsByStatus(ProvisionStatus.Pending, actions);
  const successActions = getActionsByStatus(ProvisionStatus.Complete, actions);
  const errorActions = getActionsByStatus(ProvisionStatus.Failed, actions);

  return (
    <>
      <NotificationContext.Provider value={addSingleAction}>
        {children}
      </NotificationContext.Provider>
      <Snackbar open={actions.length > 0}>
        <div>
          {actions.length === 1 && (
            <ActionList
              hideHeader
              dismissAction={dismissAction}
              provisionStatus={
                actions[0].__typename === "ProvisionRequest"
                  ? actions[0].status
                  : ProvisionStatus.Pending
              }
              actions={actions}
            />
          )}
          {actions.length > 1 && (
            <>
              <Card>
                <CardContent>
                  <List disablePadding>
                    <ListItem onClick={toggle}>
                      <ListItemIcon>
                        <Badge badgeContent={actions.length} color="secondary">
                          <SimCardIcon />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText>Devices are Provisioning</ListItemText>
                      <ListItemSecondaryAction>
                        {open ? (
                          <IconButton onClick={toggle}>
                            <ExpandLess />
                          </IconButton>
                        ) : (
                          <IconButton onClick={toggle}>
                            <ExpandMore />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
              <Collapse in={!open}>
                {pendingActions.length > 0 && (
                  <ActionList
                    provisionStatus={ProvisionStatus.Pending}
                    dismissAction={dismissAction}
                    actions={pendingActions}
                  />
                )}
                {successActions.length > 0 && (
                  <ActionList
                    provisionStatus={ProvisionStatus.Complete}
                    dismissAction={dismissAction}
                    actions={successActions}
                  />
                )}
                {errorActions.length > 0 && (
                  <ActionList
                    provisionStatus={ProvisionStatus.Failed}
                    dismissAction={dismissAction}
                    actions={errorActions}
                  />
                )}
              </Collapse>
            </>
          )}
        </div>
      </Snackbar>
    </>
  );
};

export const useNotification = () => useContext(NotificationContext);

export default Notifications;
