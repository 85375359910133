import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MeDocument, useConfirmEmailMutation } from "../../generated/graphql";
import { useConfirmEmailToken } from "./shared";

const EmailConfirmation = () => {
  const token = useConfirmEmailToken();
  const [emailConfirmMutation, { data, loading }] = useConfirmEmailMutation();
  const history = useHistory();

  useEffect(() => {
    token &&
      emailConfirmMutation({
        variables: { token },
        refetchQueries: [{ query: MeDocument }],
      });
  }, [emailConfirmMutation, token]);

  if (!token) {
    return <div>Invalid Confirmation Link</div>;
  }

  if (loading) {
    return (
      <div>
        <CircularProgress />
        Confirming Email
      </div>
    );
  }

  if (data) {
    return (
      <Box>
        {data.confirmEmail ? (
          <Typography variant="h6">
            Email address change has been verified. Log in with your new email
            address to continue.
          </Typography>
        ) : (
          <Typography variant="h6">
            Email address change has verification could not be completed. Please
            log in with your former username and repeat the steps in order to
            change your email address.
          </Typography>
        )}
        <Button variant="contained" onClick={() => history.push("/login")}>
          Back to Login
        </Button>
      </Box>
    );
  }

  return <div>Error</div>;
};

export default EmailConfirmation;
