const BugIcon = () => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6395 11.5466C21.6227 12.1594 21.1046 12.64 20.4916 12.64H18.5462V13.2025C18.5462 13.9712 18.3746 14.6996 18.0681 15.3521L20.1855 17.4695C20.6248 17.9089 20.6248 18.6212 20.1855 19.0605C19.7461 19.4999 19.0338 19.4998 18.5944 19.0605L16.6701 17.1362C15.8 17.8419 14.6914 18.265 13.4837 18.265V9.68688C13.4837 9.4539 13.2948 9.26501 13.0618 9.26501H12.2181C11.9851 9.26501 11.7962 9.4539 11.7962 9.68688V18.265C10.5886 18.265 9.4799 17.8419 8.60979 17.1362L6.68548 19.0605C6.24609 19.4999 5.53379 19.4998 5.09445 19.0605C4.65513 18.6212 4.65513 17.9089 5.09445 17.4695L7.21184 15.3521C6.90534 14.6996 6.73371 13.9712 6.73371 13.2025V12.64H4.78838C4.17539 12.64 3.65722 12.1594 3.64042 11.5466C3.62295 10.9109 4.13313 10.39 4.76496 10.39H6.73371V8.32476L5.09445 6.68549C4.65513 6.24614 4.65513 5.53384 5.09445 5.0945C5.53383 4.65515 6.24609 4.65515 6.68548 5.0945L8.60596 7.01501H16.6739L18.5944 5.09453C19.0338 4.65518 19.746 4.65518 20.1854 5.09453C20.6247 5.53388 20.6247 6.24618 20.1854 6.68553L18.5462 8.32476V10.39H20.515C21.1468 10.39 21.657 10.9109 21.6395 11.5466ZM12.6751 1.39001C10.5005 1.39001 8.73762 3.15289 8.73762 5.32751H16.6126C16.6126 3.15289 14.8497 1.39001 12.6751 1.39001Z"
        fill="white"
      />
    </svg>
  );
};

export default BugIcon;
