import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FilledInput,
  FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface PasswordEntryProps {
  label: string;
  name?: string;
  autocomplete?: string;
  password: string;
  setPassword: (password: string) => void;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  onBlur?: (password: any) => void;
  gutterBottom?: boolean;
}

const PasswordInput = ({
  label,
  password,
  setPassword,
  required,
  disabled,
  autocomplete,
  helperText,
  onBlur,
  gutterBottom,
}: PasswordEntryProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl
      fullWidth
      disabled={disabled}
      margin="normal"
      sx={{ mb: gutterBottom ? 1 : 0 }}
    >
      <InputLabel required>{label}</InputLabel>
      <FilledInput
        // label={label}
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        onBlur={(event) => onBlur && onBlur(event.target.value)}
        fullWidth
        autoComplete={autocomplete}
        required
        endAdornment={
          !disabled && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default PasswordInput;
