import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { StyledEngineProvider } from "@material-ui/core";
import { AlertContextProvider } from "./components/alerts/AlertContextProvider";

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Device: {
        fields: {
          enabledFeatures: {
            merge(_existing = [], incoming: any[]) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  uri: "/graphql",
  credentials: "include",
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <StyledEngineProvider injectFirst>
        <AlertContextProvider>
          <App />
        </AlertContextProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
