import React, { useState } from "react";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { useForgotPasswordMutation } from "../../generated/graphql";

interface ForgotPasswordFormProps {
  email: string;
  setEmail: (email: string) => void;
}

const ForgotPasswordForm = ({ email, setEmail }: ForgotPasswordFormProps) => {
  const [passwordIsReset, setPasswordIsReset] = useState(false);
  const [forgotPasswordMutation, { loading }] = useForgotPasswordMutation();

  const resetForgottenPassword = () =>
    forgotPasswordMutation({ variables: { email } }).then(() =>
      setPasswordIsReset(true)
    );

  return (
    <form
      onSubmit={(event) => {
        resetForgottenPassword();
        event.preventDefault();
      }}
    >
      <Grid container spacing={3}>
        <>
          {!passwordIsReset ? (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  type="email"
                  autoComplete="username"
                  fullWidth
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!email || loading}
                >
                  Send Password Reset Email
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography>Password Reset Email Has Been Sent</Typography>
              </Grid>
            </>
          )}
        </>
      </Grid>
    </form>
  );
};

export default ForgotPasswordForm;
