import { MenuItem, Select } from "@material-ui/core";
import React from "react";
import { useUserContext } from "../userContext";

const UserSwitcher = () => {
  const { currentAccount, accounts, setCurrentAccount } = useUserContext();

  if (accounts.length === 1) {
    return <></>;
  }

  return (
    <Select
      id="outlined-select-currency"
      label="Account"
      value={currentAccount.id}
      variant="standard"
      color="primary"
      sx={{ mr: 2 }}
      onChange={(event) => setCurrentAccount(event.target.value)}
    >
      {accounts.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default UserSwitcher;
