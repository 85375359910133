import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import React from "react";
import AstroDialog from "./AstroDialog";

interface ConfirmationDialogProps {
  title?: String;
  message?: String | JSX.Element[] | JSX.Element;
  open: boolean;
  onClose: () => void;
}

const InformationDialog: React.FC<ConfirmationDialogProps> = ({
  title = "Are you sure?",
  message,
  open,
  onClose,
  children,
}) => {
  return (
    <AstroDialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        {message && (
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </AstroDialog>
  );
};

export default InformationDialog;
