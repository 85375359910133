import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Theme } from "@material-ui/core/styles";

import HomeIcon from "@material-ui/icons/Home";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PersonIcon from "@material-ui/icons/Person";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useUserContext } from "../../components/userContext";
import TailFinReversed from "../../components/icons/TailFinReversed";
import BankSafe from "../../components/icons/BankSafe";
import BugIcon from "../../components/icons/Bug";
import ReportBugDialog from "./ReportBugDialog";
import { useDialog } from "../../helpers/useDialog";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    backgroundColor: "rgba(0, 0, 0, 0.25)",
    overflow: "hidden",
  },
  reportABug: {
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider,
    height: 60,
    position: "relative",
    "&:hover > .MuiListItemText-root": {
      display: "initial",
    },
    "&:hover > .MuiListItemText-root > .MuiTypography-root": {
      color: "white",
      display: "block",
      position: "absolute",
      left: "125%",
      top: "50%",
      transform: "translateY(-50%)",
    },

    marginTop: 100,
  },
  list: {
    paddingTop: 150,
  },
  listItemFirstOfType: {
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
  },
  listItem: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.divider,
    height: 60,
    position: "relative",
    "&:hover > .MuiListItemText-root": {
      display: "initial",
    },
    "&:hover > .MuiListItemText-root > .MuiTypography-root": {
      color: "white",
      display: "block",
      position: "absolute",
      left: "125%",
      top: "50%",
      transform: "translateY(-50%)",
    },
  },
  paper: {
    background: theme.palette.background.transparent,
    overflow: "visible",
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  icon: {
    justifyContent: "center",
    // color: "white",
    // position: "relative",
  },
  iconLabel: {
    display: "none",
  },
}));

const Sidebar = () => {
  const { isAstroFinance, isAstroAdmin } = useUserContext();
  const { open: openReportBug, onOpen, onClose } = useDialog();

  const classes = useStyles();

  const theme = useTheme();
  const isMobileDisplay = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* <CssBaseline /> */}
      {!isMobileDisplay ? (
        <Drawer
          variant="persistent"
          anchor="left"
          open={true}
          classes={{
            paperAnchorDockedLeft: classes.paper,
            root: classes.drawer,
          }}
        >
          <List className={classes.list}>
            <ListItem
              button
              component={Link}
              to="/"
              className={clsx(classes.listItem, classes.listItemFirstOfType)}
            >
              <ListItemIcon className={classes.icon}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText className={classes.iconLabel}>Home</ListItemText>
            </ListItem>
            {(!isAstroFinance || isAstroAdmin) && (
              <ListItem
                button
                component={Link}
                to="/devices"
                className={classes.listItem}
              >
                <ListItemIcon className={classes.icon}>
                  <TailFinReversed />
                </ListItemIcon>
                <ListItemText className={classes.iconLabel}>
                  Devices
                </ListItemText>
              </ListItem>
            )}
            <ListItem
              button
              component={Link}
              to="/settings"
              className={classes.listItem}
            >
              <ListItemIcon className={classes.icon}>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText className={classes.iconLabel}>
                Settings
              </ListItemText>
            </ListItem>
            {(isAstroFinance || isAstroAdmin) && (
              <div>
                <ListItem
                  button
                  component={Link}
                  to="/invoices"
                  className={classes.listItem}
                >
                  <ListItemIcon className={classes.icon}>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.iconLabel}>
                    Invoices
                  </ListItemText>
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/finance"
                  className={classes.listItem}
                >
                  <ListItemIcon className={classes.icon}>
                    <BankSafe />
                  </ListItemIcon>
                  <ListItemText className={classes.iconLabel}>
                    Finance
                  </ListItemText>
                </ListItem>
              </div>
            )}
            {isAstroAdmin && (
              <ListItem
                button
                component={Link}
                to="/admin"
                className={classes.listItem}
              >
                <ListItemIcon className={classes.icon}>
                  <VerifiedUserIcon />
                </ListItemIcon>
                <ListItemText className={classes.iconLabel}>Admin</ListItemText>
              </ListItem>
            )}

            {(isAstroFinance || isAstroAdmin) && (
              <ListItem button onClick={onOpen} className={classes.reportABug}>
                <ListItemIcon className={classes.icon}>
                  <BugIcon />
                </ListItemIcon>
                <ListItemText className={classes.iconLabel}>
                  Report A Bug
                </ListItemText>
              </ListItem>
            )}
          </List>
          {openReportBug && (
            <ReportBugDialog open={openReportBug} onClose={onClose} />
          )}
        </Drawer>
      ) : null}
    </>
  );
};

export default Sidebar;
