import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

const LoadingIndicator = () => {
  return (
    <Box
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingIndicator;
