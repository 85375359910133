import { useState } from "react";

export const useDialog = (initialState?: boolean) => {
  const [open, setOpen] = useState<boolean>(initialState || false);

  return {
    open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    toggle: () => setOpen(!open),
  };
};
