import { useLocation } from "react-router-dom";

export enum Screen {
  Login = "/login/",
  UpdatePassword = "/login/update-password",
  ForgotPassword = "/login/forgot-password",
  ResetPassword = "/login/reset-password",
  RequestAccess = "/login/request-access",
  RegisterAccount = "/login/register-account",
  Register = "/login/register",
  ConfirmEmail = "/login/confirm-email",
}

export function useQueryToken() {
  const params = new URLSearchParams(useLocation().search);
  if (!params.has("email") || !params.has("token")) {
    return null;
  }
  return {
    email: params.get("email") || "",
    token: params.get("token") || "",
  };
}

export function useConfirmEmailToken() {
  const params = new URLSearchParams(useLocation().search);
  return params.get("token") || "";
}
