import { Button, Box } from "@material-ui/core";
import React from "react";
import {
  UpdateExpiredPasswordMutationVariables,
  useUpdateExpiredPasswordMutation,
} from "../../generated/graphql";
import { Screen } from "./shared";
import RffPassword from "../../components/inputs/RffPassword";
import { Form } from "react-final-form";
import { LoadingButton } from "@material-ui/lab";
import RffTextField from "../../components/inputs/RffTextField";
import * as VALIDATORS from "../../helpers/validators";

interface UpdatePasswordProps {
  initialData?: {
    email: string;
    password: string;
  };
  onLoginSuccess: () => void;

  setScreen: (screen: Screen) => void;
}

type FormData = UpdateExpiredPasswordMutationVariables & {
  passwordConfirm: string;
};

const UpdatePasswordForm = ({
  initialData,
  onLoginSuccess,
  setScreen,
}: UpdatePasswordProps) => {
  const [
    updatePasswordMutation,
    { loading },
  ] = useUpdateExpiredPasswordMutation();

  const updatePassword = ({ email, oldPassword, newPassword }: FormData) =>
    updatePasswordMutation({
      variables: { email, oldPassword, newPassword },
    }).then(onLoginSuccess);

  const validate = (data: Partial<FormData>) => ({
    email: VALIDATORS.email(data.email),
    newPassword: VALIDATORS.password(data.newPassword, data.oldPassword),
    passwordConfirm: VALIDATORS.matches(data.newPassword, data.passwordConfirm),
  });

  return (
    <Form
      onSubmit={updatePassword}
      validate={validate}
      initialValues={
        initialData && {
          email: initialData.email,
          oldPassword: initialData.password,
        }
      }
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <RffTextField
              label="Email"
              type="email"
              name="email"
              fullWidth
              disabled={Boolean(initialData?.email)}
              autoFocus={!Boolean(initialData?.email)}
              required
            />
            <RffPassword
              name="oldPassword"
              label="Current Password"
              fullWidth
              disabled={Boolean(initialData?.password)}
            />
            <RffPassword
              name="newPassword"
              label="New Password"
              disabled={loading}
              autoComplete="new-password"
              autoFocus={Boolean(initialData?.email)}
              required
              fullWidth
            />
            <RffPassword
              name="passwordConfirm"
              label="Confirm Password"
              disabled={loading}
              autoComplete="new-password"
              validatePassword
              required
              fullWidth
            />
            <LoadingButton
              sx={{ mt: 3 }}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
              size="large"
            >
              Update Password
            </LoadingButton>
            <Button
              variant="text"
              color="secondary"
              fullWidth
              onClick={() => setScreen(Screen.Login)}
            >
              Cancel
            </Button>
          </Box>
        </form>
      )}
    />
  );
};

export default UpdatePasswordForm;
