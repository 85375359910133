import { LoadingButton } from "@material-ui/lab";
import React from "react";
import { useForgotPasswordMutation } from "../../../generated/graphql";

interface SendEmailLinkButtonProps {
  email: String;
  resetMessage: String;
}

const ResendTokenButton = ({
  email,
  resetMessage,
}: SendEmailLinkButtonProps) => {
  const [
    forgotPasswordMutation,
    { loading, data },
  ] = useForgotPasswordMutation();

  const sent = Boolean(!loading && data);

  return (
    <LoadingButton
      sx={{ mt: 3 }}
      fullWidth
      color="primary"
      size="large"
      variant="contained"
      loading={loading}
      onClick={() => forgotPasswordMutation({ variables: { email } })}
      disabled={sent}
    >
      {sent ? "Email Sent" : resetMessage}
    </LoadingButton>
  );
};

export default ResendTokenButton;
