import React, {
  FunctionComponent,
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLogoutMutation, useMeQuery } from "../../generated/graphql";
import Login from "../../pages/login";
import { UserContext as UserContextType } from "./types";
import { toUserDataFromAuth, toUserContext } from "./util";

// this will never be empty outside this component
export const UserContext = createContext<UserContextType | null>(null);

export const useUserContext = (): UserContextType => {
  const data = useContext(UserContext);
  if (!data) {
    throw Error("User Context should never be null");
  }

  return data;
};

interface UserContextProviderProps {}

// NEEDS SOME MAJOR CLEANUP
const UserContextProvider: FunctionComponent<UserContextProviderProps> = ({
  children,
}) => {
  const [currentAccount, setCurrentAccount] = useState<string | null>(null);
  const [desiredPath, setDesiredPath] = useState<string | null>(null);
  const { loading, error, data, refetch } = useMeQuery();
  const [logoutMutation] = useLogoutMutation();
  const history = useHistory();

  const location = useLocation();
  const onLoginScreen = location.pathname.startsWith("/login");

  const shouldRedirectToLogin = !loading && data && !data.me && !onLoginScreen;

  // needs to be done in a hook, or else we get too many redirects
  useEffect(() => {
    // setDesiredPath(pathname);
    if (shouldRedirectToLogin) {
      setDesiredPath(location.pathname);
      history.push("/login");
    }
  }, [shouldRedirectToLogin, history, location, setDesiredPath]);

  const onLogin = useCallback(() => {
    refetch().then(() =>
      desiredPath && !desiredPath.startsWith("/login")
        ? history.push(desiredPath)
        : history.push("/")
    );
  }, [refetch, desiredPath, history]);

  const logout = useCallback(() => logoutMutation().then(() => refetch()), [
    logoutMutation,
    refetch,
  ]);

  if (history.location.pathname.startsWith("/login")) {
    return <Login onLoginSuccess={onLogin} />;
  }

  if (loading) {
    return <div />;
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  // set state
  if (data && !data.me) {
    return <Login onLoginSuccess={onLogin} />;
  }

  const state = data && toUserDataFromAuth(data);

  if (!state) {
    return <div>ERROR</div>;
  }

  const value = toUserContext(
    state,
    currentAccount,
    setCurrentAccount,
    onLogin,
    logout
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const UseUserContext = () => useContext(UserContext);

// export const useUserContext = UseUserContext;

export default UserContextProvider;
