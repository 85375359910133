import { useCallback, useEffect, useState } from "react";
import {
  DeviceActionsFragment,
  DeviceUpdatesQuery,
  useDeviceUpdatesQuery,
} from "../../generated/graphql";
import { useUserContext } from "../userContext";

const useDeviceWatcher = (addAction: (a: DeviceActionsFragment) => void) => {
  const {
    currentAccount: { id: accountId },
  } = useUserContext();
  const [lastUpdate, setLastUpdate] = useState(new Date());

  const showNotifications = useCallback(
    (data: DeviceUpdatesQuery) => {
      const actions = data.account.actions;

      setLastUpdate(
        actions
          .map((action) => new Date(action.modified))
          .reduce(
            (a, b) => (a.toISOString() > b.toISOString() ? a : b),
            lastUpdate
          )
      );

      actions.forEach((action) => {
        addAction(action);
      });
    },
    [lastUpdate, addAction]
  );

  const { fetchMore } = useDeviceUpdatesQuery({
    variables: { accountId, since: lastUpdate },
    onCompleted: (data) => showNotifications,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      return fetchMore({ variables: { accountId } }).then(
        (result) => result.data && showNotifications(result.data)
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [fetchMore, accountId, lastUpdate, showNotifications]);

  return {};
};

export default useDeviceWatcher;
