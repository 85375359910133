import { IconButton, InputAdornment } from "@material-ui/core";
import React, { useState } from "react";
import RffTextField, { RffTextFieldProps } from "./RffTextField";
import { Visibility, VisibilityOff } from "@material-ui/icons";

type RffPasswordProps = Exclude<RffTextFieldProps, "type" | "validate"> & {
  validatePassword?: boolean;
};

const RffPassword = ({
  validatePassword,
  InputProps,
  ...props
}: RffPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <RffTextField
      type={showPassword ? "text" : "password"}
      validate={(val) =>
        validatePassword && val && val.length < 8
          ? "Password must be at least 8 characters"
          : undefined
      }
      InputProps={{
        ...InputProps,
        endAdornment: !props.disabled && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default RffPassword;
