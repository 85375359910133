import React from "react";
import {
  CircularProgress,
  IconButton,
  Link,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { ProvisionAction, ProvisionStatus } from "../../generated/graphql";

import { Link as RouterLink } from "react-router-dom";

import { ProvisionActionNotification } from ".";
import ProvisionActionIcon from "../ProvisionActionIcon";

import InformationDialog from "../modals/InformationDialog";

interface ActionItemProps {
  action: ProvisionActionNotification;
  dismissAction: (actionId: string) => void;
  title: string;
}

const actionMessage = {
  [ProvisionAction.Activate]: {
    [ProvisionStatus.Pending]: "Device is Activating",
    [ProvisionStatus.Complete]: "Device is Activated",
    [ProvisionStatus.Failed]: "Activation Failed",
  },
  [ProvisionAction.Lock]: {
    [ProvisionStatus.Pending]: "Device is Locking",
    [ProvisionStatus.Complete]: "Device is Locked",
    [ProvisionStatus.Failed]: "Device did not lock",
  },
  [ProvisionAction.Unlock]: {
    [ProvisionStatus.Pending]: "Device is Unlocking",
    [ProvisionStatus.Complete]: "Device is Unlocked",
    [ProvisionStatus.Failed]: "Device did not unlock",
  },
  [ProvisionAction.Deactivate]: {
    [ProvisionStatus.Pending]: "Device is Deactivating",
    [ProvisionStatus.Complete]: "Device is Deactivated",
    [ProvisionStatus.Failed]: "Device did not deactivate",
  },
  [ProvisionAction.ChangeIccid]: {
    [ProvisionStatus.Pending]: "Changing SIM",
    [ProvisionStatus.Complete]: "Sim Changed",
    [ProvisionStatus.Failed]: "Swap Sim Failed",
  },
};

const ActionItem = ({ action, dismissAction, title }: ActionItemProps) => {
  if (action.__typename !== "ProvisionRequest") {
    throw Error();
  }

  const [showErrorModal, setShowErrorModel] = React.useState<boolean>(false);
  const handleToggleErrorModal = () => {
    if (action.status === ProvisionStatus.Failed) {
      setShowErrorModel((prevState) => !prevState);
    }
  };

  return (
    <ListItem disableGutters>
      <InformationDialog
        open={showErrorModal}
        title={title}
        message={action.errorMessage || ""}
        onClose={handleToggleErrorModal}
      />
      <ListItemIcon>
        <ProvisionActionIcon provisionAction={action.action} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography>{actionMessage[action.action][action.status]}</Typography>
        }
        secondary={
          <Link
            component={RouterLink}
            to={`/devices/${action.device.id}`}
            variant="body2"
            color="inherit"
          >
            ICCID: {action.device.iccid}
          </Link>
        }
        onClick={handleToggleErrorModal}
      />
      <ListItemSecondaryAction>
        {action.status === ProvisionStatus.Pending ? (
          <CircularProgress size="2rem" />
        ) : (
          <IconButton onClick={() => dismissAction(action.id)}>
            <CheckIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ActionItem;
