import {
  AppBar,
  Container,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import { Theme } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import { useUserContext } from "../components/userContext";
import SettingsIcon from "@material-ui/icons/Settings";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Link } from "react-router-dom";
import UserSwitcher from "../components/userSwitcher";
import Sidebar from "./components/Sidebar";
import BottomBar from "./components/BottomBar";
import LogoImage from "../logo@2x.png";

const drawerWidth = 60;

const useStyles = makeStyles((theme: Theme) => ({
  appRoot: {},
  appBar: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: "4px",
    backgroundColor: "transparent",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth * 2}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth * 2,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {},
  linkUnstyled: {
    color: "inherit",
    textDecoration: "none",
  },
  title: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    maxWidth: "66%",
  },
  logo: {
    maxWidth: 285,
    maxHeight: 66,
    width: "100%",
    height: "auto",
  },
  userAccountDropDown: {
    // display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  userAccountIcon: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  userDropDown: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth * 2,
  },
}));

const Layout: FunctionComponent = ({ children }) => {
  const classes = useStyles();
  const user = useUserContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | Element>(
    null
  );
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isMobileDisplay = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container className={classes.appRoot}>
      <Sidebar />
      <AppBar
        color="default"
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: !isMobileDisplay,
        })}
        elevation={0}
      >
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link to="/" className={classes.linkUnstyled}>
              <img
                src={LogoImage}
                alt="Astronautics"
                className={classes.logo}
              />
            </Link>
          </Typography>
          <div className={classes.userDropDown}>
            <UserSwitcher />
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className={classes.userAccountIcon}
            >
              <AccountCircle />
            </IconButton>
            <div onClick={handleMenu} className={classes.userAccountDropDown}>
              <Typography variant="body1">Welcome,</Typography>
              <Typography display="block" variant="h6">
                {user.name} <KeyboardArrowDownIcon />
              </Typography>
            </div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <Link to={"/settings"} className={classes.linkUnstyled}>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Settings</Typography>
                </MenuItem>
              </Link>
              <MenuItem onClick={user.logout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Logout</Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: !isMobileDisplay,
        })}
      >
        {children}
      </main>
      <BottomBar />
    </Container>
  );
};

export default Layout;
